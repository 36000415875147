//
// Inspiration from https://www.w3schools.com/howto/howto_css_modals.asp
//

const modal = document.getElementById("contact-modal");

// When the user clicks on a button, open the modal
document.querySelectorAll("[data-modal=contact-modal]").forEach(btn =>
  btn.onclick = () =>
    modal.setAttribute("data-open", "true")
);

// When the user clicks on on the close button (x), close the modal
document.getElementsByClassName("close")[0].onclick = () =>
  modal.removeAttribute("data-open");


// When the user clicks anywhere outside of the modal, close it
window.onclick = (event) => {
  if (event.target == modal) {
    modal.removeAttribute("data-open");
  }
}

//
// Inspiration from https://css-tricks.com/snippets/css/typewriter-effect/
//

var TxtType = function(el, toRotate, period) {
  this.toRotate = toRotate;
  this.el = el;
  this.loopNum = 0;
  this.period = parseInt(period, 10) || 2000;
  this.txt = '';
  this.tick();
  this.isDeleting = false;
};

TxtType.prototype.tick = function() {
  var i = this.loopNum % this.toRotate.length;
  var fullTxt = this.toRotate[i];

  if (this.isDeleting) {
  this.txt = fullTxt.substring(0, this.txt.length - 1);
  } else {
  this.txt = fullTxt.substring(0, this.txt.length + 1);
  }

  this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';

  var that = this;
  var delta = 150 - Math.random() * 100;

  if (this.isDeleting) { delta /= 2; }

  if (!this.isDeleting && this.txt === fullTxt) {
  delta = this.period;
  this.isDeleting = true;
  } else if (this.isDeleting && this.txt === '') {
  this.isDeleting = false;
  this.loopNum++;
  delta = 500;
  }

  setTimeout(function() {
  that.tick();
  }, delta);
};

window.onload = function() {
  var elements = document.getElementsByClassName('typewrite');
  for (var i=0; i<elements.length; i++) {
      var toRotate = elements[i].getAttribute('data-type');
      var period = elements[i].getAttribute('data-period');
      if (toRotate) {
        new TxtType(elements[i], JSON.parse(toRotate), period);
      }
  }
  // INJECT CSS
  var css = document.createElement("style");
  css.type = "text/css";
  css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #213543}";
  document.body.appendChild(css);
};

//
// Animated highlight logic
//

const container = document.getElementById("animated-background-container");
const animatedElems = container.getElementsByClassName("animated-background");
for (const elem of animatedElems) {
  elem.style.setProperty("--animated-layout", "0%");
}

const transitionLength = 300;
const transitionDelta = 150;

container.onmouseenter = () => {
  for (let i = 0; i < animatedElems.length; i++) {
    const elem = animatedElems[i];
    setTimeout(() => {
      elem.style.setProperty("text-decoration-line", "none");
      elem.style.setProperty("text-decoration-thickness", "0px");
      elem.style.setProperty("--animated-layout", "100%");
    }, i * (transitionLength + transitionDelta));
  }
};

container.onmouseleave = () => {
  setTimeout(() => {
    for (let i = 0; i < animatedElems.length; i++) {
      const elem = animatedElems[animatedElems.length - 1 - i];
      setTimeout(() => {
        elem.style.setProperty("text-decoration-line", "underline");
        elem.style.setProperty("text-decoration-thickness", "2px");
        elem.style.setProperty("--animated-layout", "0%");
      }, i * (transitionLength + transitionDelta));
    }
  }, 200);
};

//
// Join waitlist button effect logic
//

const joinButton = document.getElementById("join-button");
joinButton.onmousemove = e => {
  const rect = joinButton.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;

  joinButton.style.setProperty("--mouse-x", `${x}px`);
  joinButton.style.setProperty("--mouse-y", `${y}px`);
};
